@keyframes appear-fade {
  from {
    transform: translateY(0);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(30px);
  }
}

@layer components {
  .anima-input-error-message {
    animation: appear-fade 300ms ease-in-out forwards;
  }
}
